import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getPopsRequest: [],
  getPopsFailure: [],
  getPopsSuccess: ['data', 'total'],
  setPopsSort: ['sort', 'direction'],
  setPopsPage: ['page', 'limit'],
  getPopRequest: ['id'],
  getPopSuccess: ['data'],
  getPopFailure: [],
  resetPopData: [],
  removePopRequest: ['id', 'callback'],
  removePopFinish: [],
  addPopRequest: ['data', 'callback'],
  addPopFailure: [],
  updatePopRequest: ['id', 'data', 'callback'],
  updatePopFinish: [],
  getPopFunctionsRequest: [],
  getPopFunctionsFailure: [],
  getPopFunctionsSuccess: ['data', 'total'],
})

const INITIAL_STATE = {
  options: {
    sort: 'name',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 20,
  },
  data: [],
  dataPopId: [],
  loading: false,
  dataFunctions: [],
}

const getPopsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getPopsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getPopsSuccess = (state = INITIAL_STATE, { data, total }) => ({
    ...state,
    options: {
      ...state.options,
      total,
    },
  data,
    loading: false,
})

const setPopsSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setPopsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const getPopRequest = (state = INITIAL_STATE) => ({
    ...state,
    loading: true,
})

const getPopSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataPopId: {
    ...data,
    active: String(data.active),
  },
  loading: false,
})

const getPopFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const resetPopData = () => ({
  ...INITIAL_STATE,
})

const removePopRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const removePopFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const addPopRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const addPopFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const updatePopRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const updatePopFinish = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getPopFunctionsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getPopFunctionsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  dataFunctions: data,
  loading: false,
})

const getPopFunctionsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_POPS_REQUEST]: getPopsRequest,
  [Types.GET_POPS_FAILURE]: getPopsFailure,
  [Types.GET_POPS_SUCCESS]: getPopsSuccess,
  [Types.SET_POPS_SORT]: setPopsSort,
  [Types.SET_POPS_PAGE]: setPopsPage,
  [Types.GET_POP_REQUEST]: getPopRequest,
  [Types.GET_POP_SUCCESS]: getPopSuccess,
  [Types.GET_POP_FAILURE]: getPopFailure,
  [Types.RESET_POP_DATA]: resetPopData,
  [Types.REMOVE_POP_REQUEST]: removePopRequest,
  [Types.REMOVE_POP_FINISH]: removePopFinish,
  [Types.ADD_POP_REQUEST]: addPopRequest,
  [Types.ADD_POP_FAILURE]: addPopFailure,
  [Types.UPDATE_POP_REQUEST]: updatePopRequest,
  [Types.UPDATE_POP_FINISH]: updatePopFinish,
  [Types.GET_POP_FUNCTIONS_REQUEST]: getPopFunctionsRequest,
  [Types.GET_POP_FUNCTIONS_FAILURE]: getPopFunctionsFailure,
  [Types.GET_POP_FUNCTIONS_SUCCESS]: getPopFunctionsSuccess,
})
