import { Creators as HelpdeskDashboardActions } from '~/store/ducks/helpdeskDashboard'
import React from 'react'
import 'moment/locale/pt-br'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useEffectOnce, useUnmount } from 'react-use'
import moment from 'moment'
import Autocomplete from '~/components/Autocomplete'
import DateRangePicker from '~/components/DateRangePicker'
import RangeTimeSelect from '~/components/RangeTimeSelect'
import PropTypes from 'prop-types'

const helpdeskDashboardSelector = ({ helpdeskDashboard }) => helpdeskDashboard

function DashboardHelpdeskFilters({
  handlePropertyFilterChange,
  getFilterValue,
}) {
  const { filtersData, filters } = useSelector(helpdeskDashboardSelector)
  const dispatch = useDispatch()

  const handleDatesChange = (dates) => {
    const { startDate, endDate } = dates
    handlePropertyFilterChange('start_date', startDate)
    handlePropertyFilterChange('end_date', endDate)
  }
  const handleTimerChange = (value) => {
    const from = moment().subtract(value, 'd')
    const to = moment()
    handlePropertyFilterChange('start_date', from)
    handlePropertyFilterChange('end_date', to)
  }
  const colDateToFilterOptions = [
    { id: 'created_at', name: 'Data de abertura' },
    { id: 'closed_at', name: 'Data de fechamento' },
    { id: 'expected_date', name: 'Data prevista' },
  ]

  useEffectOnce(() => {
    dispatch(HelpdeskDashboardActions.getHelpdeskDashboardFiltersRequest())
    dispatch(HelpdeskDashboardActions.getHelpdeskDashboardDataRequest())
  })
  useUnmount(() => {
    dispatch(HelpdeskDashboardActions.resetHelpdeskDashboard())
  })
  function handleChangeFilter(property, value) {
    dispatch(
      HelpdeskDashboardActions.setHelpdeskDashboardFilter(property, value),
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} >
        <DateRangePicker
          startDate={moment(getFilterValue('start_date'))}
          startDateId="analitycal-start"
          endDate={moment(getFilterValue('end_date'))}
          isOutsideRange={() => false}
          endDateId="analitycal-end"
          onDatesChange={handleDatesChange}
        />
        <RangeTimeSelect onSelect={handleTimerChange} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} >
        <Autocomplete
          onChange={(_, option) => {
            handlePropertyFilterChange('date_column_to_filter', option ? option.value : 'created_at')
          }}
          options={colDateToFilterOptions?.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          name="date_column_to_filter"
          value={getFilterValue('date_column_to_filter')}
          label="Filtrar período por"
          placeholder="Filtrar período por"
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Departamentos"
          fullWidth
          select
          value={filters.department}
          onChange={(ev) => handleChangeFilter('department', ev.target.value)}
        >
          {filtersData.departments.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Atendentes"
          fullWidth
          select
          value={filters.attendant}
          onChange={(ev) => handleChangeFilter('attendant', ev.target.value)}
        >
          {filtersData.attendants.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <TextField
          variant="outlined"
          label="Tempo de espera"
          fullWidth
          select
          value={filters.delay}
          onChange={(ev) => handleChangeFilter('delay', ev.target.value)}
        >
          {filtersData.delays.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

DashboardHelpdeskFilters.propTypes = {
  handlePropertyFilterChange: PropTypes.func.isRequired,
  getFilterValue: PropTypes.func.isRequired,
}

export default DashboardHelpdeskFilters
