import React from 'react'
import PropTypes from 'prop-types'
import { mdiPencil } from '@mdi/js'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  IconButton,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { useSelector, useDispatch } from 'react-redux'
import { Creators as EquipmentsActions } from '~/store/ducks/equipments'
import ShowHideColumns from '~/components/ShowHideColumns'
import CustomIcon from '~/components/CustomIcon'
import styles from './styles'

const equipmentsSelector = ({ equipments }) => equipments
const useStyles = makeStyles(styles)

const optionsShowHideColumn = [
  { name: 'cod', label: 'Código', disabled: true },
  { name: 'type', label: 'Tipo' },
  { name: 'dealership', label: 'Concessionária' },
  { name: 'department', label: 'Departamento' },
  { name: 'responsible', label: 'Responsável' },
  { name: 'brand', label: 'Marca' },
  { name: 'model', label: 'Modelo' },
  { name: 'conservation_state_description', label: 'Estado de conservação' },
  { name: 'observation', label: 'Observação' },
]

export default function EquipmentList({
  fromUsers,
  handleSortChange,
  handleEditEquipments,
  handleRemoveEquipment,
}) {
  const classes = useStyles()
  const { data, options, showHideColumns } = useSelector(equipmentsSelector)
  const { sort, direction } = options
  const dispatch = useDispatch()

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(
      EquipmentsActions.setChangeShowHideColumnsEquipments(newColumnsTable),
    )
  }

  return (
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          {showHideColumns.cod && (
            <TableCell>
              <TableSortLabel
                active={sort === 'cod_equipment'}
                onClick={() => handleSortChange('cod_equipment')}
                direction={direction}
              >
                Código
              </TableSortLabel>
            </TableCell>
          )}
          {showHideColumns.type && <TableCell>Tipo</TableCell>}
          {showHideColumns.dealership && <TableCell>Concessionária</TableCell>}
          {showHideColumns.department && <TableCell>Departamento</TableCell>}

          {!fromUsers && showHideColumns.responsible && (
            <TableCell align="center">Responsável</TableCell>
          )}
          {showHideColumns.brand && (
            <TableCell>
              <TableSortLabel
                active={sort === 'brand'}
                onClick={() => handleSortChange('brand')}
                direction={direction}
              >
                Marca
              </TableSortLabel>
            </TableCell>
          )}
          {showHideColumns.model && (
            <TableCell>
              <TableSortLabel
                active={sort === 'model'}
                onClick={() => handleSortChange('model')}
                direction={direction}
              >
                Modelo
              </TableSortLabel>
            </TableCell>
          )}
          {showHideColumns.observation && (
            <TableCell>
              <TableSortLabel
                active={sort === 'conservation_state_description'}
                onClick={() => handleSortChange('conservation_state_description')}
                direction={direction}
              >
                Estado de convervação
              </TableSortLabel>
            </TableCell>
          )}
          {showHideColumns.observation && (
            <TableCell>
              <TableSortLabel
                active={sort === 'observation'}
                onClick={() => handleSortChange('observation')}
                direction={direction}
              >
                Observação
              </TableSortLabel>
            </TableCell>
          )}
          <TableCell>
            <ShowHideColumns
              showHideColumns={showHideColumns}
              optionsShowHideColumn={optionsShowHideColumn}
              onChangeShowHideColumn={handleSetChangeShowHideColumn}
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length > 0 &&
          data.map((item) => (
            <TableRow hover key={item.id} className={classes.tableRow}>
              {showHideColumns.cod && (
                <TableCell className={!item.responsible?.active ? classes.inactiveResponsible : ''}>{item.cod_equipment || '-'}</TableCell>
              )}
              {showHideColumns.type && (
                <TableCell className={!item.responsible?.active ? classes.inactiveResponsible : ''}>{item.equipment_type.name || '-'}</TableCell>
              )}
              {showHideColumns.dealership && (
                <TableCell className={!item.responsible?.active ? classes.inactiveResponsible : ''}>{item.dealership.name || '-'}</TableCell>
              )}

              {showHideColumns.department && (
                <TableCell className={!item.responsible?.active ? classes.inactiveResponsible : ''}>{item.department?.name || '-'}</TableCell>
              )}

              {!fromUsers && showHideColumns.responsible && (
                <TableCell align="center" className={!item.responsible?.active ? classes.inactiveResponsible : ''}>
                  {item.responsible?.name || '-'}
                </TableCell>
              )}
              {showHideColumns.brand && (
                <TableCell className={!item.responsible?.active ? classes.inactiveResponsible : ''}>{item.brand || '-'}</TableCell>
              )}
              {showHideColumns.model && (
                <TableCell className={!item.responsible?.active ? classes.inactiveResponsible : ''}>{item.model || '-'}</TableCell>
              )}
              {showHideColumns.conservation_state_description && (
                <TableCell className={!item.responsible?.active ? classes.inactiveResponsible : ''}>{item.conservation_state_description || '-'}</TableCell>
              )}
              {showHideColumns.observation && (
                <TableCell className={!item.responsible?.active ? classes.inactiveResponsible : ''}>{item.observation || '-'}</TableCell>
              )}
              {!fromUsers && (
                <TableCell className={!item.responsible?.active ? classes.inactiveResponsible : ''} align="right">
                  <div className={classes.tableCellActions}>
                    <IconButton
                      className={classes.editIcon}
                      onClick={() => handleEditEquipments(item.id)}
                    >
                      <CustomIcon pathComponent={mdiPencil} />
                    </IconButton>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => handleRemoveEquipment(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </TableCell>
              )}
              <TableCell />
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

EquipmentList.propTypes = {
  handleSortChange: PropTypes.func,
  handleEditEquipments: PropTypes.func,
  handleRemoveEquipment: PropTypes.func,
  fromUsers: PropTypes.bool,
}

EquipmentList.defaultProps = {
  handleSortChange: () => { },
  handleEditEquipments: () => { },
  handleRemoveEquipment: () => { },
  fromUsers: false,
}
