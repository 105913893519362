import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  IconButton,
  Grid,
  Button,
  Avatar,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons'
import { find } from 'lodash'
import { mdiPencil } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import history from '~/helpers/history'

import { Creators as UsersActions } from '~/store/ducks/users'
import { Creators as UserActions } from '~/store/ducks/user'

import ShowHideColumns from '~/components/ShowHideColumns'
import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import CustomIcon from '~/components/CustomIcon'

import { useSmallScreen } from '~/helpers/useMediaQuery'
import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'
import EmptyList from '~/components/EmptyList'
import UsersFilters from './components/UsersFilters'
import styles from './styles'

const usersSelector = ({ users }) => users

const sessionSelector = ({ session }) => session

const useStyles = makeStyles(styles)

function UsersPage() {
  const {
    user: { roles },
  } = useSelector(sessionSelector)
  const userRoles = roles.map((r) => r.name)

  const hasEditPermission = !userRoles.includes('rh_consult')

  const classes = useStyles()
  const dispatch = useDispatch()
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [userId, setUserId] = useState(null)
  const { data, options, loading, showHideColumns } = useSelector(usersSelector)

  const { sort, direction } = options
  const isSmallScreen = useSmallScreen()

  const optionsShowHideColumn = [
    { name: 'name', label: 'Nome', disabled: true },
    { name: 'function', label: 'Função' },
    { name: 'department', label: 'Departamento' },
    { name: 'dealership', label: 'Concessionária' },
    { name: 'active', label: 'Ativo' },
    { name: 'firstBond', label: 'Primeiro Vínculo' },
  ]

  useEffect(() => {
    dispatch(UsersActions.getUsersRequest())
    dispatch(UsersActions.getUsersFilterDealershipsRequest())
    dispatch(UsersActions.getUsersFilterFunctionsRequest())
  }, [])

  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(UsersActions.setUsersSort(newSort, sortDirection))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(UsersActions.setUsersPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(UsersActions.setUsersPage(page, options.limit))
  }

  const handleRemoveUser = (id) => {
    setUserId(id)
    setOpenConfirmDialog(true)
  }

  const handleRemoveCancel = () => {
    setUserId(null)
    setOpenConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      UserActions.removeUserRequest(userId, () => {
        dispatch(UsersActions.getUsersRequest())
        setUserId(null)
        setOpenConfirmDialog(false)
      }),
    )
  }

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }

    dispatch(UsersActions.setChangeShowHideColumnsUsers(newColumnsTable))
  }

  const getFilterValue = (property) => {
    if (options.filters) {
      const filterValue = find(options.filters, { name: property })
      return filterValue.value
    }
    return null
  }

  const month = getFilterValue('month')

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.gridContainerButton}
        justifyContent="space-between"
      >
        {hasEditPermission && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAdd}
              onClick={() => history.push('users/new')}
            >
              <AddIcon className={classes.iconAdd} /> Adicionar Colaborador
            </Button>
          </Grid>
        )}
      </Grid>

      {!isSmallScreen ? (
        <UsersFilters />
      ) : (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.filterWropper}>
              <FilterIcon color="primary" className={classes.filterIcon} />
              <Typography variant="h5" color="primary">
                Filtros
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UsersFilters />
              </Grid>
            </Grid>
          </AccordionDetails>
          <div style={{ paddingTop: 8 }} />
        </Accordion>
      )}
      <Portlet className={classes.grow}>
        <div className={classes.tableWrapper}>
          {!loading && data.length === 0 && (
            <EmptyList message="Não há colaboradores para serem visualizados" />
          )}
          {loading && <OverlayLoading />}
          {!loading && (
            <div className={classes.listWrapper}>
              {data.length > 0 && (
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {showHideColumns.name && (
                        <TableCell>
                          <TableSortLabel
                            active={sort === 'name'}
                            onClick={() => handleSortChange('name')}
                            direction={direction}
                          >
                            Nome
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {showHideColumns.function && (
                        <TableCell>
                          <TableSortLabel
                            active={sort === 'function_name'}
                            onClick={() => handleSortChange('function_name')}
                            direction={direction}
                          >
                            Função
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {showHideColumns.department && (
                        <TableCell>
                          <TableSortLabel
                            active={sort === 'function_department_name'}
                            onClick={() =>
                              handleSortChange('function_department_name')
                            }
                            direction={direction}
                          >
                            Departamento
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {showHideColumns.dealership && (
                        <TableCell>
                          <TableSortLabel
                            active={sort === 'function_dealership_name'}
                            onClick={() =>
                              handleSortChange('function_dealership_name')
                            }
                            direction={direction}
                          >
                            Concessionária
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {showHideColumns.active && (
                        <TableCell>
                          <TableSortLabel
                            active={sort === 'active'}
                            onClick={() => handleSortChange('active')}
                            direction={direction}
                          >
                            Ativo
                          </TableSortLabel>
                        </TableCell>
                      )}
                      {/* <TableCell>Ramal</TableCell> */}
                      <TableCell align="center">
                        <TableSortLabel
                          active={sort === 'last_login_at'}
                          onClick={() => handleSortChange('last_login_at')}
                          direction={direction}
                        >
                          Último Login
                        </TableSortLabel>
                      </TableCell>
                      {showHideColumns.firstBond && month && (
                        <TableCell>Primeiro Vínculo</TableCell>
                      )}
                      <TableCell>
                        <ShowHideColumns
                          showHideColumns={showHideColumns}
                          optionsShowHideColumn={optionsShowHideColumn}
                          onChangeShowHideColumn={handleSetChangeShowHideColumn}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item) => (
                      <TableRow
                        hover
                        key={item.id}
                        className={classes.tableRow}
                      >
                        <TableCell>
                          <Avatar alt={item.name} src={item.url_image} />
                        </TableCell>
                        {showHideColumns.name && (
                          <TableCell>{item.name || '-'}</TableCell>
                        )}
                        {showHideColumns.function && (
                          <TableCell>{item.function_name || '-'}</TableCell>
                        )}
                        {showHideColumns.department && (
                          <TableCell>
                            {item.function_department_name || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.dealership && (
                          <TableCell>
                            {item.function_dealership_name || '-'}
                          </TableCell>
                        )}
                        {showHideColumns.active && (
                          <TableCell>{item.active ? 'Sim' : 'Não'}</TableCell>
                        )}
                        {/* <TableCell>{item.ramal_next_ip || '-'}</TableCell> */}
                        {item.last_login_at !== null ? (
                          <TableCell align="center">
                            {moment(item.last_login_at).format('DD/MM/YYYY LT')}
                          </TableCell>
                        ) : (
                          <TableCell align="center">-</TableCell>
                        )}
                        {showHideColumns.firstBond &&
                          month &&
                          (item.dt_primeiro_contrato !== null ? (
                            <TableCell>
                              {moment(item.dt_primeiro_contrato).format(
                                'DD/MM/YYYY',
                              )}
                            </TableCell>
                          ) : (
                            <TableCell>Data não cadastrada</TableCell>
                          ))}

                        {hasEditPermission ? (
                          <TableCell align="right">
                            <div className={classes.tableCellActions}>
                              <IconButton
                                className={classes.editIcon}
                                onClick={() =>
                                  history.push(`users/${item.id}/edit`)
                                }
                              >
                                <CustomIcon pathComponent={mdiPencil} />
                              </IconButton>
                              <IconButton
                                className={classes.deleteIcon}
                                onClick={() => handleRemoveUser(item.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                className={classes.visibilityIcon}
                                onClick={() =>
                                  history.push(`users/${item.id}/view`)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell align="center">
                            <IconButton
                              className={classes.visibilityIcon}
                              onClick={() =>
                                history.push(`users/${item.id}/view`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          )}
        </div>

        {!loading && data.length > 0 && (
          <ListFooter
            options={options}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
          />
        )}
      </Portlet>
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir este colaborador?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </div>
  )
}

export default UsersPage
