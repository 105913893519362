import {
  call,
  takeLatest,
  put,
  all,
  select,
  debounce,
} from 'redux-saga/effects'
import moment from 'moment'
import { get } from 'lodash'
import configureApi from '~/helpers/api'
import { Creators as FlashMessageActions } from '../ducks/flashMessage'
import {
  Types as HelpdeskDashboardTypes,
  Creators as HelpdeskDashboardActions,
} from '../ducks/helpdeskDashboard'

const filterSelector = ({ helpdeskDashboard }) => helpdeskDashboard.filters

function* getHelpdeskDashboardFilters() {
  const api = configureApi()
  try {
    const { attendants, departments } = yield all({
      attendants: call(api.get, 'get_attendants'),
      departments: call(api.get, 'get_departments'),
    })

    const attendantsData = attendants.data.map((a) => ({
      name: a.name,
      value: a.id,
    }))

    const departmentsData = departments.data.map((d) => ({
      name: d.name,
      value: d.id,
    }))

    yield put(
      HelpdeskDashboardActions.getHelpdeskDashboardFiltersSuccess(
        departmentsData,
        attendantsData,
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(HelpdeskDashboardActions.getHelpdeskDashboardFiltersFailure())
  }
}

function* getHelpdeskDashboardData() {

  const filters = yield select(filterSelector)

  try {
    const api = configureApi()
    const startDate = filters.start_date.format('YYYY-MM-DD')
    const endDate = filters.end_date.format('YYYY-MM-DD')    
    const formattedParams = {
      department_id: filters.department,
      attendant_id: filters.attendant,
      delay_id: filters.delay,
      date_column_to_filter: filters.date_column_to_filter,
      between: `${startDate},${endDate}`
    }

    const { data } = yield call(api.get, 'helpdesk', {
      params: formattedParams,
    })
    yield put(
      HelpdeskDashboardActions.getHelpdeskDashboardDataSuccess(
        data.sort((a, b) => b.total - a.total),
      ),
    )
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(HelpdeskDashboardActions.getHelpdeskDashboardDataFailure())
  }
}

function* getHelpdeskCallsData({ subject }) {
  try {
    const api = configureApi()
    const filters = yield select(filterSelector)
    const mDate = moment(filters.date)
    if (!mDate.isValid()) {
      return
    }
    const { data } = yield call(api.get, 'get_calls_data', {
      params: {
        year: mDate.year(),
        month: mDate.month() + 1,
        department_id: filters.department,
        attendant_id: filters.attendant,
        delay_id: filters.delay,
        subject,
      },
    })

    yield put(HelpdeskDashboardActions.getHelpdeskCallsDataSuccess(data))
  } catch (e) {
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
    yield put(HelpdeskDashboardActions.getHelpdeskDashboardDataFailure())
  }
}

function* reloadHelpdeskDashboardData() {
  yield put(HelpdeskDashboardActions.getHelpdeskDashboardDataRequest())
}

export default function* () {
  yield takeLatest(
    HelpdeskDashboardTypes.GET_HELPDESK_DASHBOARD_DATA_REQUEST,
    getHelpdeskDashboardData,
  )
  yield debounce(
    400,
    HelpdeskDashboardTypes.SET_HELPDESK_DASHBOARD_FILTER,
    reloadHelpdeskDashboardData,
  )
  yield takeLatest(
    HelpdeskDashboardTypes.GET_HELPDESK_DASHBOARD_FILTERS_REQUEST,
    getHelpdeskDashboardFilters,
  )
  yield takeLatest(
    HelpdeskDashboardTypes.GET_HELPDESK_CALLS_DATA_REQUEST,
    getHelpdeskCallsData,
  )
}
