import React, { useState, useEffect } from 'react'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Grid,
  Button,
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'

import moment from 'moment'
import { mdiMessageReply } from '@mdi/js'
import {
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as CallsActions } from '~/store/ducks/calls'

import ShowHideColumns from '~/components/ShowHideColumns'
import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import CustomIcon from '~/components/CustomIcon'
import SearchInput from '~/components/SearchInput'
import EmptyList from '~/components/EmptyList'
import { useSmallScreen, useTabletScreen } from '~/helpers/useMediaQuery'
import OverlayLoading from '~/components/OverlayLoading'
import history from '~/helpers/history'

import CallDialog from './components/CallDialog'
import CallsFilter from './components/CallsFilter'

import styles from './styles'

const callsSelector = ({ calls }) => calls

const useStyles = makeStyles(styles)

function CallsPage({
  match: {
    params: { id },
  },
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSmallScreen = useSmallScreen()
  const isTabletScreen = useTabletScreen()
  const {
    data,
    options,
    loading,
    departments: { data: departments },
    dealerships: { data: dealerships },
    countdown: { count },
    showHideColumns,
  } = useSelector(callsSelector)

  const { sort, direction, filters } = options

  const optionsShowHideColumn = [
    { name: 'id', label: 'Id', disabled: true },
    { name: 'subject', label: 'Assunto' },
    { name: 'requester', label: 'Solicitante' },
    { name: 'responsible', label: 'Responsável' },
    { name: 'status', label: 'Status' },
    { name: 'created_at', label: 'Data de Cadastro' },
    { name: 'expected_date', label: 'Concluir até' },
    { name: 'department', label: 'Departamento' },
  ]

  const fromEmail = !!id

  const dealershipSelected = find(filters, { name: 'dealership_id' })
  const departmentSelected = find(filters, { name: 'department_id' })

  const dealershipsHasDepartmentSelected =
    departmentSelected?.value &&
    dealerships?.filter((dealership) =>
      dealership.departments.filter(
        (item) => item.id === departmentSelected?.value,
      ),
    )

  const subjects =
    (departmentSelected?.value &&
      dealershipsHasDepartmentSelected[0].departments?.find(
        (item) => item.id === departmentSelected?.value,
      )?.subjects) ||
    []

  const [openCallDialog, setOpenCallDialog] = useState(false)

  const [callId, setCallId] = useState(null)

  const resetCallsList = () => {
    dispatch(CallsActions.getCallsRequest())
  }

  useEffect(() => {
    dispatch(
      CallsActions.getCallsRequest(() => {
        dispatch(CallsActions.startCallsCountdown())
      }),
    )
    dispatch(CallsActions.getCallsFilterDealershipsRequest())
    dispatch(CallsActions.getCallsFilterStatusRequest())
    dispatch(CallsActions.getCallsFilterDepartmentsRequest())

    return () => {
      dispatch(CallsActions.resetCalls())
      dispatch(CallsActions.endCallsCountdown())
    }
  }, [])

  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(CallsActions.setCallsSort(newSort, sortDirection))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(CallsActions.setCallsPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(CallsActions.setCallsPage(page, options.limit))
  }

  const handleSearchChange = (value) => {
    dispatch(CallsActions.setCallsSearch(value))
  }

  const handleCreateCalled = () => {
    setOpenCallDialog(true)
  }

  const handleCloseCallDialog = () => {
    resetCallsList()
    setOpenCallDialog(false)
    setCallId(null)
    history.push('/calls')
  }

  const handleEditCall = (cId) => {
    setCallId(cId)
    setOpenCallDialog(true)
  }

  useEffect(() => {
    if (fromEmail) {
      handleEditCall(id)
    }
  }, [id, fromEmail])

  const handlePropertyFilterChange = (property, value) => {
    dispatch(CallsActions.setCallsPropertyFilter(property, value))
  }

  const getFilterValue = (property) => {
    if (options.filters) {
      const filterValue = find(options.filters, { name: property })
      return filterValue.value
    }
    return null
  }

  const handleSetChangeShowHideColumn = (name, value) => {
    const newColumnsTable = {
      ...showHideColumns,
      [name]: value,
    }
    dispatch(CallsActions.setChangeShowHideColumnsCalls(newColumnsTable))
  }

  return (
    <>
      <div className={classes.root}>
        <Grid container justifyContent="space-between">
          {!isTabletScreen && (
            <Grid
              item
              style={{ paddingBottom: isSmallScreen ? 8 : '' }}
              xs={12}
              md={6}
            >
              <SearchInput
                onChange={(e) => handleSearchChange(e.target.value)}
                placeholder="Pesquise por ID, assunto, solicitante e responsável..."
              />
            </Grid>
          )}
          <Grid item container justifyContent="flex-end" xs={12} md={6}>
            <Grid item style={{ paddingTop: isSmallScreen ? 8 : '' }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonCall}
                onClick={handleCreateCalled}
              >
                <CustomIcon
                  pathComponent={mdiMessageReply}
                  className={classes.iconCall}
                />
                CRIAR CHAMADO
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {isTabletScreen ? (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.filtersTitle}>
                <FilterIcon color="primary" className={classes.filterIcon} />
                <Typography variant="h5" color="primary">
                  Filtros
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <SearchInput
                    onChange={(e) => handleSearchChange(e.target.value)}
                    placeholder="Pesquise por ID, assunto, solicitante e responsável..."
                  />
                </Grid>
                <Grid item xs={12}>
                  <CallsFilter
                    subjects={subjects}
                    dealershipSelected={dealershipSelected}
                    getFilterValue={getFilterValue}
                    departments={departments}
                    handlePropertyFilterChange={handlePropertyFilterChange}
                    classes={classes}
                    departmentSelected={departmentSelected}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : (
          <CallsFilter
            subjects={subjects}
            dealershipSelected={dealershipSelected}
            getFilterValue={getFilterValue}
            departments={departments}
            handlePropertyFilterChange={handlePropertyFilterChange}
            classes={classes}
            departmentSelected={departmentSelected}
          />
        )}
        <Portlet className={classes.grow}>
          <div className={classes.tableWrapper}>
            {loading && <OverlayLoading />}
            {!loading && (
              <div className={classes.listWrapper}>
                {data?.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        {showHideColumns.id && (
                          <TableCell align="left">
                            <TableSortLabel
                              active={sort === 'id'}
                              onClick={() => handleSortChange('id')}
                              direction={direction}
                            >
                              ID
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {showHideColumns.subject && (
                          <TableCell align="center">
                            <TableSortLabel
                              active={sort === 'subject'}
                              onClick={() => handleSortChange('subject')}
                              direction={direction}
                            >
                              Assunto
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {showHideColumns.department && (
                          <TableCell align="center">
                            <TableSortLabel
                              active={sort === 'department'}
                              onClick={() => handleSortChange('department')}
                              direction={direction}
                            >
                              Departamento
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {showHideColumns.requester && (
                          <TableCell align="center">
                            <TableSortLabel
                              active={sort === 'requester_name'}
                              onClick={() => handleSortChange('requester_name')}
                              direction={direction}
                            >
                              Solicitante
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {showHideColumns.responsible && (
                          <TableCell align="center">
                            <TableSortLabel
                              active={sort === 'responsible_name'}
                              onClick={() =>
                                handleSortChange('responsible_name')
                              }
                              direction={direction}
                            >
                              Responsável
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {showHideColumns.status && (
                          <TableCell align="center">
                            <TableSortLabel
                              active={sort === 'status'}
                              onClick={() => handleSortChange('status')}
                              direction={direction}
                            >
                              Status
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {showHideColumns.created_at && (
                          <TableCell align="center">
                            <TableSortLabel
                              active={sort === 'created_at'}
                              onClick={() => handleSortChange('created_at')}
                              direction={direction}
                            >
                              Data de Cadastro
                            </TableSortLabel>
                          </TableCell>
                        )}
                        {showHideColumns.expected_date && (
                          <TableCell align="center">Concluir até</TableCell>
                        )}
                        <TableCell className={classes.showHideColumns}>
                          <ShowHideColumns
                            showHideColumns={showHideColumns}
                            optionsShowHideColumn={optionsShowHideColumn}
                            onChangeShowHideColumn={
                              handleSetChangeShowHideColumn
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((item) => (
                        <TableRow
                          hover
                          key={item.id}
                          className={classes.tableRow}
                          onClick={() => handleEditCall(item.id)}
                        >
                          {showHideColumns.id && (
                            <TableCell align="left">{item.id || '-'}</TableCell>
                          )}
                          {showHideColumns.subject && (
                            <TableCell align="center">
                              {item.subject || '-'}
                            </TableCell>
                          )}
                          {showHideColumns.department && (
                            <TableCell align="center">
                              {item.department || '-'}
                            </TableCell>
                          )}
                          {showHideColumns.requester && (
                            <TableCell align="center">
                              {item.requester_name || '-'}
                            </TableCell>
                          )}
                          {showHideColumns.responsible && (
                            <TableCell align="center">
                              {item.responsible_name || '-'}
                            </TableCell>
                          )}
                          {showHideColumns.status && (
                            <TableCell align="center">
                              <Chip
                                className={classes.statusChip}
                                style={{
                                  backgroundColor:
                                    item.status_color || '#B3B3B3',
                                }}
                                size="medium"
                                label={item.status || '-'}
                              />
                            </TableCell>
                          )}
                          {showHideColumns.created_at && (
                            <TableCell align="center">
                              {moment(item.created_at || '-').format(
                                'DD/MM/YYYY HH:mm',
                                'HH/MM',
                              )}
                            </TableCell>
                          )}
                          {showHideColumns.expected_date && (
                            <TableCell align="center">
                              {item.expected_date
                                ? moment(item.expected_date).format(
                                  'DD/MM/YYYY',
                                )
                                : '-'}
                            </TableCell>
                          )}
                          <TableCell />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            )}
            {!loading && data.length === 0 && (
              <EmptyList message="Não há chamados para serem visualizados" />
            )}
          </div>

          {!loading && data.length > 0 && (
            <ListFooter
              options={options}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
              hasCountDown
              countDown={count}
            />
          )}
        </Portlet>
      </div>
      <CallDialog
        id={callId}
        open={openCallDialog}
        onClose={handleCloseCallDialog}
      />
    </>
  )
}

CallsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

CallsPage.defaultProps = {
  match: {
    params: {
      id: null,
    },
  },
}

export default CallsPage
