import React from 'react'

import { makeStyles, Grid } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

function MarketingDashboardPage() {
  const classes = useStyles()

  return (
    <div style={{ height: '100%' }}>
      <Grid container justifyContent="center" className={classes.root}>
        <Grid item xs={12}>
          <iframe
            src="https://airtable.com/embed/appLM5egvwriBVM9s/shr1wytEzjgOQE9lS?viewControls=on"
            style={{ border: 'none' }}
            title="calendar"
            width="100%"
            height="100%"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default MarketingDashboardPage
