import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getEquipmentsRequest: ['responsibleId'],
  getEquipmentsFailure: [],
  getEquipmentsSuccess: ['data', 'total'],
  exportEquipmentsRequest: [],
  exportEquipmentsFailure: [],
  exportEquipmentsSuccess: ['data'],
  setEquipmentsSort: ['sort', 'direction'],
  setEquipmentsPage: ['page', 'limit'],
  setEquipmentsSearch: ['value'],
  setEquipmentsPropertyFilter: ['property', 'value'],
  getEquipmentsFilterEquipmentsTypeRequest: [],
  getEquipmentsFilterEquipmentsTypeSuccess: ['data'],
  getEquipmentsFilterEquipmentsTypeFailure: [],
  getEquipmentsFilterDepartmentsRequest: [],
  getEquipmentsFilterDepartmentsSuccess: ['data'],
  getEquipmentsFilterDepartmentsFailure: [],
  getEquipmentsFilterResponsiblesRequest: [],
  getEquipmentsFilterResponsiblesSuccess: ['data'],
  getEquipmentsFilterResponsiblesFailure: [],
  getEquipmentsFilterDealershipsRequest: [],
  getEquipmentsFilterDealershipsSuccess: ['data'],
  getEquipmentsFilterDealershipsFailure: [],
  resetEquipmentsFilters: [],
  getEquipmentsListQrCodeRequest: [],
  getEquipmentsListQrCodeSuccess: ['data'],
  getEquipmentsListQrCodeFailure: [],
  setChangeShowHideColumnsEquipments: ['newTableColumns'],
})

const INITIAL_STATE = {
  options: {
    pagination: 1,
    sort: 'cod_equipment',
    direction: 'asc',
    page: 0,
    total: 0,
    limit: 20,
    filters: [
      { name: 'responsible_active', value: null },
      { name: 'equipment_type_id', value: null },
      { name: 'dealership_id', value: null },
      { name: 'department_id', value: null },
      { name: 'responsible_id', value: null },
      { name: 'conservation_state', value: null },
    ],
  },
  data: [],
  exportedData: undefined,
  loading: false,
  equipmentsTypes: {
    data: [],
    loading: false,
  },
  dealerships: {
    data: [],
    loading: false,
  },
  departments: {
    data: [],
    loading: false,
  },
  responsibles: {
    data: [],
    loading: false,
  },
  showHideColumns: {
    cod: true,
    type: true,
    dealership: true,
    department: true,
    responsible: true,
    brand: true,
    model: true,
    conservation_state_description: true,
    observation: true,
  },
}

const resetEquipmentsFilters = () => ({
  ...INITIAL_STATE,
})

const setEquipmentsPropertyFilter = (
  state = INITIAL_STATE,
  { property, value },
) => {
  return {
    ...state,
    options: {
      ...state.options,
      filters: state.options.filters.map((filter) => {
        if (filter.name === property) {
          return {
            ...filter,
            value,
          }
        }
        return filter
      }),
    },
  }
}

const getEquipmentsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getEquipmentsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const getEquipmentsSuccess = (state = INITIAL_STATE, { data, total }) => ({
  ...state,
  options: {
    ...state.options,
    total,
  },
  data,
  loading: false,
})

const exportEquipmentsRequest = (state = INITIAL_STATE) => ({
  ...state,
})

const exportEquipmentsFailure = (state = INITIAL_STATE) => ({
  ...state,
})

const exportEquipmentsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  exportedData: data,
})

const setEquipmentsSort = (state = INITIAL_STATE, { sort, direction }) => ({
  ...state,
  options: {
    ...state.options,
    sort,
    direction,
    page: 0,
  },
})

const setEquipmentsPage = (state = INITIAL_STATE, { page, limit }) => ({
  ...state,
  options: {
    ...state.options,
    page,
    limit,
  },
})

const setEquipmentsSearch = (state = INITIAL_STATE, { value }) => ({
  ...state,
  options: {
    ...state.options,
    search: value,
    page: 0,
  },
})

const getEquipmentsFilterEquipmentsTypeRequest = (state = INITIAL_STATE) => ({
  ...state,
  equipmentsTypes: {
    ...state.equipmentsTypes,
    loading: true,
  },
})

const getEquipmentsFilterEquipmentsTypeSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  equipmentsTypes: {
    ...state.equipmentsTypes,
    data,
    loading: false,
  },
})

const getEquipmentsFilterEquipmentsTypeFailure = (state = INITIAL_STATE) => ({
  ...state,
  equipmentsTypes: {
    ...state.equipmentsTypes,
    loading: false,
  },
})

const getEquipmentsFilterDepartmentsRequest = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: true,
  },
})

const getEquipmentsFilterDepartmentsSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  departments: {
    ...state.departments,
    data,
    loading: false,
  },
})

const getEquipmentsFilterDepartmentsFailure = (state = INITIAL_STATE) => ({
  ...state,
  departments: {
    ...state.departments,
    loading: false,
  },
})

const getEquipmentsFilterResponsiblesRequest = (state = INITIAL_STATE) => ({
  ...state,
  responsibles: {
    ...state.responsibles,
    loading: true,
  },
})

const getEquipmentsFilterResponsiblesSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  responsibles: {
    ...state.responsibles,
    data,
    loading: false,
  },
})

const getEquipmentsFilterResponsiblesFailure = (state = INITIAL_STATE) => ({
  ...state,
  responsibles: {
    ...state.responsibles,
    loading: false,
  },
})

const getEquipmentsFilterDealershipsRequest = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: true,
  },
})

const getEquipmentsFilterDealershipsSuccess = (
  state = INITIAL_STATE,
  { data },
) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    data,
    loading: false,
  },
})

const getEquipmentsFilterDealershipsFailure = (state = INITIAL_STATE) => ({
  ...state,
  dealerships: {
    ...state.dealerships,
    loading: false,
  },
})

const getEquipmentsListQrCodeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
})

const getEquipmentsListQrCodeSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
})

const getEquipmentsListQrCodeFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
})

const setChangeShowHideColumnsEquipments = (
  state = INITIAL_STATE,
  { newTableColumns },
) => {
  localStorage.setItem(
    'caid.columnsTableEquipments',
    JSON.stringify(newTableColumns),
  )
  return {
    ...state,
    showHideColumns: newTableColumns,
  }
}

export default createReducer(INITIAL_STATE, {
  [Types.GET_EQUIPMENTS_REQUEST]: getEquipmentsRequest,
  [Types.GET_EQUIPMENTS_FAILURE]: getEquipmentsFailure,
  [Types.GET_EQUIPMENTS_SUCCESS]: getEquipmentsSuccess,
  [Types.EXPORT_EQUIPMENTS_REQUEST]: exportEquipmentsRequest,
  [Types.EXPORT_EQUIPMENTS_FAILURE]: exportEquipmentsFailure,
  [Types.EXPORT_EQUIPMENTS_SUCCESS]: exportEquipmentsSuccess,
  [Types.SET_EQUIPMENTS_SORT]: setEquipmentsSort,
  [Types.SET_EQUIPMENTS_PAGE]: setEquipmentsPage,
  [Types.SET_EQUIPMENTS_SEARCH]: setEquipmentsSearch,
  [Types.SET_EQUIPMENTS_PROPERTY_FILTER]: setEquipmentsPropertyFilter,
  [Types.GET_EQUIPMENTS_FILTER_EQUIPMENTS_TYPE_REQUEST]:
    getEquipmentsFilterEquipmentsTypeRequest,
  [Types.GET_EQUIPMENTS_FILTER_EQUIPMENTS_TYPE_SUCCESS]:
    getEquipmentsFilterEquipmentsTypeSuccess,
  [Types.GET_EQUIPMENTS_FILTER_EQUIPMENTS_TYPE_FAILURE]:
    getEquipmentsFilterEquipmentsTypeFailure,
  [Types.GET_EQUIPMENTS_FILTER_DEPARTMENTS_REQUEST]:
    getEquipmentsFilterDepartmentsRequest,
  [Types.GET_EQUIPMENTS_FILTER_DEPARTMENTS_SUCCESS]:
    getEquipmentsFilterDepartmentsSuccess,
  [Types.GET_EQUIPMENTS_FILTER_DEPARTMENTS_FAILURE]:
    getEquipmentsFilterDepartmentsFailure,
  [Types.GET_EQUIPMENTS_FILTER_RESPONSIBLES_REQUEST]:
    getEquipmentsFilterResponsiblesRequest,
  [Types.GET_EQUIPMENTS_FILTER_RESPONSIBLES_SUCCESS]:
    getEquipmentsFilterResponsiblesSuccess,
  [Types.GET_EQUIPMENTS_FILTER_RESPONSIBLES_FAILURE]:
    getEquipmentsFilterResponsiblesFailure,
  [Types.GET_EQUIPMENTS_FILTER_DEALERSHIPS_REQUEST]:
    getEquipmentsFilterDealershipsRequest,
  [Types.GET_EQUIPMENTS_FILTER_DEALERSHIPS_SUCCESS]:
    getEquipmentsFilterDealershipsSuccess,
  [Types.GET_EQUIPMENTS_FILTER_DEALERSHIPS_FAILURE]:
    getEquipmentsFilterDealershipsFailure,
  [Types.RESET_EQUIPMENTS_FILTERS]: resetEquipmentsFilters,
  [Types.GET_EQUIPMENTS_LIST_QR_CODE_REQUEST]: getEquipmentsListQrCodeRequest,
  [Types.GET_EQUIPMENTS_LIST_QR_CODE_SUCCESS]: getEquipmentsListQrCodeSuccess,
  [Types.GET_EQUIPMENTS_LIST_QR_CODE_FAILURE]: getEquipmentsListQrCodeFailure,
  [Types.SET_CHANGE_SHOW_HIDE_COLUMNS_EQUIPMENTS]:
    setChangeShowHideColumnsEquipments,
})
