/* eslint-disable no-dupe-keys */
import { eventChannel, END } from 'redux-saga'

import {
  call,
  takeLatest,
  put,
  select,
  debounce,
  take,
  cancelled,
} from 'redux-saga/effects'
import { find, get } from 'lodash'
import configureApi from '~/helpers/api'

import { Types as CallsTypes, Creators as CallsActions } from '../ducks/calls'

import { Creators as FlashMessageActions } from '../ducks/flashMessage'

const optionsSelector = ({ calls }) => calls.options
const selectorCountdown = ({ calls }) => calls.countdown
const selectorCountdownPipeline = ({ calls }) => calls.countdownPipeline
const userSelector = ({ session: { user } }) => user

function* getCalls({ callback }) {
  const options = yield select(optionsSelector)
  const user = yield select(userSelector)
  const getFilter = (property) => {
    const filterValue = find(options.filters, { name: property })
    return filterValue.value
  }
  const search = []
  if (options.search) {
    search.push(`requester_name,${options.search}`)
    search.push(`id,${options.search}`)
    search.push(`responsible_name,${options.search}`)
    search.push(`subject,${options.search}`)
  }

  try {
    const api = configureApi()
    const formattedParams = {
      pagination: 1,
      page: options.page + 1,
      limit: options.limit,
      order: `${options.sort},${options.direction}`,
      search,
      'where[dealership_id]': getFilter('dealership_id'),
      'where[department_id]': getFilter('department_id'),
      'where[subject_id]': getFilter('subject_id'),
      'where[priority]': getFilter('priority'),
      'wherein[status_id]': getFilter('status_id'),
      'whereor[responsible_id]': getFilter('my_calls_only')? user.id : null,
      'whereor[requester_id]': getFilter('my_calls_only') ? user.id : null,
    }
    const { data } = yield call(api.get, 'vw_calls', {
      params: formattedParams,
    })

    if (callback) {
      yield call(callback)
    }
    yield put(CallsActions.getCallsSuccess(data.data, data.total))
  } catch (e) {
    yield put(CallsActions.getCallsFailure())
  }
}

function* reloadCalls() {
  yield put(CallsActions.getCallsRequest())
}

function* getCallsFilterDealerships() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        pagination: 0,
        order: 'sort,asc',
      },
    })
    yield put(CallsActions.getCallsFilterDealershipsSuccess(data))
  } catch (e) {
    yield put(CallsActions.getCallsFilterDealershipsFailure())
  }
}

function* getCallsFilterStatus() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'status', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })
    const formattedData = data.map((item) => {
      return item.id !== 8
        ? item
        : {
          ...item,
          id: '1,2,3,4,7',
        }
    })
    yield put(CallsActions.getCallsFilterStatusSuccess(formattedData))
  } catch (e) {
    yield put(CallsActions.getCallsFilterStatusFailure())
  }
}

function* getNextCall({ callback }) {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, '/calls/next_call_answer')
    if (callback) {
      yield call(() => callback(data))
    }
    yield put(CallsActions.getNextCallFinish())
  } catch (e) {
    yield put(CallsActions.getNextCallFinish())
    const error = get(e, 'response.data.error[0]')
    yield put(
      FlashMessageActions.showMessage({
        message: error || 'Ops! Algo deu errado. Tente novamente',
        variant: 'error',
      }),
    )
  }
}

function* getCallsFilterDepartments() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'get_departments')
    yield put(CallsActions.getCallsFilterDepartmentsSuccess(data))
  } catch (e) {
    yield put(CallsActions.getCallsFilterDepartmentsFailure())
  }
}

function countdownChannel(secs) {
  let seconds = secs
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      seconds -= 1
      if (seconds >= 0) {
        emitter(seconds)
      } else {
        emitter(END)
      }
    }, 1000)
    return () => {
      clearInterval(iv)
    }
  })
}

function countdownPipelineChannel(secs) {
  let seconds = secs
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      seconds -= 1
      if (seconds >= 0) {
        emitter(seconds)
      } else {
        emitter(END)
      }
    }, 1000)
    return () => {
      clearInterval(iv)
    }
  })
}

function* startCallsCountdown() {
  const countdownSeconds = 60
  const channel = yield call(countdownChannel, countdownSeconds)
  try {
    while (true) {
      const seconds = yield take(channel)
      yield put(CallsActions.tickCallsCountdown(seconds))
      const { status } = yield select(selectorCountdown)
      if (status === 'Stopped') {
        channel.close()
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
    const { status } = yield select(selectorCountdown)
    if (status === 'Running') {
      yield put(CallsActions.getCallsRequest())
      yield put(CallsActions.startCallsCountdown())
    }
  }
}

function* startCallsPipelineCountdown() {
  const countdownSeconds = 60
  const channel = yield call(countdownPipelineChannel, countdownSeconds)
  try {
    while (true) {
      const seconds = yield take(channel)
      yield put(CallsActions.tickCallsPipelineCountdown(seconds))
      const { status } = yield select(selectorCountdownPipeline)
      if (status === 'Stopped') {
        channel.close()
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
    const { status } = yield select(selectorCountdownPipeline)
    if (status === 'Running') {
      yield put(CallsActions.getCallsRequest())
      yield put(CallsActions.startCallsPipelineCountdown())
    }
  }
}

function* getCallsPipeline({ callback }) {
  try {
    const api = configureApi()
    const formattedParams = {
      'wherein[status_id]': '1,2,3,4,5,7',
      limit: 1000,
    }

    const { data } = yield call(api.get, 'vw_calls', {
      params: formattedParams,
    })

    if (callback) {
      yield call(callback)
    }
    yield put(CallsActions.getCallsPipelineSuccess(data.data, data.total))
  } catch (e) {
    yield put(CallsActions.getCallsPipelineFailure())
  }
}

export default function* () {
  yield takeLatest(CallsTypes.GET_CALLS_REQUEST, getCalls)

  yield takeLatest(CallsTypes.SET_CALLS_SORT, reloadCalls)
  yield takeLatest(CallsTypes.SET_CALLS_PAGE, reloadCalls)
  yield debounce(1000, CallsTypes.SET_CALLS_SEARCH, reloadCalls)
  yield takeLatest(CallsTypes.SET_CALLS_PROPERTY_FILTER, reloadCalls)
  yield takeLatest(
    CallsTypes.GET_CALLS_FILTER_DEALERSHIPS_REQUEST,
    getCallsFilterDealerships,
  )
  yield takeLatest(
    CallsTypes.GET_CALLS_FILTER_STATUS_REQUEST,
    getCallsFilterStatus,
  )
  yield takeLatest(CallsTypes.GET_NEXT_CALL_REQUEST, getNextCall)
  yield takeLatest(CallsTypes.SET_CALLS_PROPERTY_FILTER, reloadCalls)
  yield takeLatest(
    CallsTypes.GET_CALLS_FILTER_DEPARTMENTS_REQUEST,
    getCallsFilterDepartments,
  )
  yield takeLatest(CallsTypes.START_CALLS_COUNTDOWN, startCallsCountdown)
  yield takeLatest(CallsTypes.START_CALLS_PIPELINE_COUNTDOWN, startCallsPipelineCountdown)
  yield takeLatest(CallsTypes.GET_CALLS_PIPELINE_REQUEST, getCallsPipeline)
}
