import React from 'react'
import { Route, Switch, Router, Redirect } from 'react-router-dom'
import BilledMonthComparativePage from '~/pages/BilledMonthComparativePage'
import history from '../helpers/history'
import PrivateRoute from './PrivateRoute'
import LoginPage from '../pages/LoginPage'
import DashboardPage from '../pages/DashboardPage'
import CallsPage from '../pages/CallsPage'
import HelpdeskDashboardPage from '../pages/HelpdeskDashboardPage'
import BIDashboardPage from '../pages/BIDashboardPage'
import BilledListPage from '../pages/BilledListPage'
import SellersPage from '../pages/SellersPage'
import ObjectivesPage from '../pages/ObjectivesPage'
import DailySalesPage from '../pages/DailySalesPage'
import BISellersDashboardPage from '../pages/BISellersDashboardPage'
import SalesGoalsPage from '../pages/SalesGoalsPage'
import RHDashboardPage from '../pages/RHDashboardPage'
import RHMonthComparative from '../pages/RHMonthComparative'
import EmployeesOrganogramPage from '../pages/EmployeesOrganogramPage'
import UsersPage from '../pages/UsersPage'
import NewUserPage from '../pages/NewUserPage'
import QuizzesPage from '../pages/QuizzesPage'
import NewQuizzePage from '../pages/NewQuizzePage'
import EquipmentDashboardPage from '../pages/EquipmentDashboardPage'
import EquipmentsPage from '../pages/EquipmentsPage'
import DealershipsPage from '../pages/DealershipsPage'
import DepartmentsPage from '../pages/DepartmentsPage'
import FunctionsPage from '../pages/FunctionsPage'
import SuperiorsPage from '../pages/SuperiorsPage'
import StatusPage from '../pages/StatusPage'
import EquipmentTypesPage from '../pages/EquipmentsTypesPage'
import NewsBoardPage from '../pages/NewsBoardPage'
import EditNewsBoardPage from '../pages/EditNewsBoardPage'
import ProfilePage from '../pages/ProfilePage'
import EquipmentsQrCodePage from '../pages/EquipmentsQrCode'
import SurveysPage from '../pages/SurveysPage'
import AnalyticalPage from '../pages/Analytical'
import AnalitycalParts from '../pages/AnalyticalPartsPage'
import AnalyticalDashboardPage from '../pages/AnalyticalDashboardPage'
import CommunicationBoardPage from '../pages/CommunicationBoardPage'
import EditCommunicationBoardPage from '../pages/EditCommunicationBoardPage'
import DailyEmails from '../pages/DailyEmails'
import NewSinglePage from '../pages/NewsSinglePage'
import Blip from '../pages/Blip'
import Ipc from '../pages/Ipc'
import PopPage from '../pages/PopPage'
import NewPopPage from '../pages/NewPopPage'
import ReceiptsPage from '../pages/ReceiptsPage'
import UsersRamalPage from '../pages/UsersRamalPage'
import CalendarPage from '../pages/CalendarPage'
import MarketingDashboardPage from '../pages/MarketingDashboardPage'
import IslandsPage from '../pages/IslandsPage'
import PipelineCalls from '../pages/PipelineCalls'

function AppRouter() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route
          exact
          path="/login/:reason?"
          title="Caid - NISSUL"
          component={LoginPage}
        />
        <PrivateRoute
          exact
          title="Home"
          path="/dashboard"
          component={DashboardPage}
        />
        <PrivateRoute
          exact
          title="Chamados"
          path="/calls"
          component={CallsPage}
        />
        <PrivateRoute
          exact
          title="Chamados"
          path="/calls/:id"
          component={CallsPage}
        />
        <PrivateRoute
          exact
          title="Dashboard - Helpdesk"
          path="/dashboard-helpdesk"
          component={HelpdeskDashboardPage}
        />
        <PrivateRoute
          exact
          title="Dashboard - Comercial"
          path="/bi-dashboard"
          component={BIDashboardPage}
          hasMonthCountdown
        />
        <PrivateRoute
          exact
          title="Faturados"
          path="/billed-list"
          component={BilledListPage}
        />
        <PrivateRoute
          exact
          title="Análise de vendedores"
          path="/sellers"
          component={SellersPage}
        />
        <PrivateRoute
          exact
          title="Comercial - Planejamentos"
          path="/objectives"
          component={ObjectivesPage}
        />
        <PrivateRoute
          exact
          title="Movimentação Diária"
          path="/daily-sales"
          component={DailySalesPage}
        />
        <PrivateRoute
          exact
          title="Dashboard - Peças"
          path="/bi-sellers-dashboard"
          component={BISellersDashboardPage}
        />
        <PrivateRoute
          exact
          title="Peças - Planejamentos"
          path="/salesgoals"
          component={SalesGoalsPage}
        />
        <PrivateRoute
          exact
          title="Dashboard - RH"
          path="/rh-dashboard"
          component={RHDashboardPage}
        />
        <PrivateRoute
          exact
          title="Comparativo Mensal"
          path="/rh-month-comparative"
          component={RHMonthComparative}
        />
        <PrivateRoute
          exact
          title="Organograma de Colaboradores"
          path="/employees-organogram"
          component={EmployeesOrganogramPage}
        />
        <PrivateRoute
          exact
          title="Colaboradores"
          path="/users"
          component={UsersPage}
        />
        <PrivateRoute
          exact
          title="Colaboradores"
          path="/users/new"
          component={NewUserPage}
        />
        <PrivateRoute
          title="Colaboradores"
          path="/users/:id/:mode"
          component={NewUserPage}
        />
        <PrivateRoute
          exact
          title="Notícias"
          path="/news-board"
          component={NewsBoardPage}
        />
        <PrivateRoute
          title="Notícias"
          path="/news-board/:id"
          component={EditNewsBoardPage}
        />
        <PrivateRoute
          exact
          title="Questionários"
          path="/quizzes"
          component={QuizzesPage}
        />
        <PrivateRoute
          exact
          title="Questionários"
          path="/quizzes/:id"
          component={NewQuizzePage}
        />
        <PrivateRoute
          exact
          title="Equipamentos"
          path="/equipments"
          component={EquipmentsPage}
        />
        <PrivateRoute
          exact
          title="Dashboard - Equipamentos"
          path="/equipment-dashboard"
          component={EquipmentDashboardPage}
        />
        <PrivateRoute
          exact
          title="Concessionárias"
          path="/dealerships"
          component={DealershipsPage}
        />
        <PrivateRoute
          exact
          title="Departamentos"
          path="/departments"
          component={DepartmentsPage}
        />
        <PrivateRoute
          exact
          title="Funções"
          path="/functions"
          component={FunctionsPage}
        />
        <PrivateRoute
          exact
          title="Superiores"
          path="/superior-list"
          component={SuperiorsPage}
        />
        <PrivateRoute
          exact
          title="Status"
          path="/status"
          component={StatusPage}
        />
        <PrivateRoute
          exact
          title="Tipos de Equipamento"
          path="/equipment_types"
          component={EquipmentTypesPage}
        />
        <PrivateRoute
          exact
          title="Meu Perfil"
          path="/profile"
          component={ProfilePage}
        />
        <PrivateRoute
          exact
          title="Consultar equipamento"
          path="/equipments-qr-code"
          component={EquipmentsQrCodePage}
        />
        <PrivateRoute
          exact
          title="Comparativo Mensal"
          path="/billed-month-comparative"
          component={BilledMonthComparativePage}
        />
        <PrivateRoute
          exact
          title="Analítico - Veículos"
          path="/analytical"
          component={AnalyticalPage}
        />
        <PrivateRoute
          exact
          title="Analítico - Peças"
          path="/analytical-parts"
          component={AnalitycalParts}
        />
        <PrivateRoute
          exact
          title="Analítico - Geral"
          path="/analytics-dashboard"
          component={AnalyticalDashboardPage}
        />
        <PrivateRoute
          exact
          title="Comunicados"
          path="/communication-board"
          component={CommunicationBoardPage}
        />
        <PrivateRoute exact path="/surveys/:id" component={SurveysPage} />
        <PrivateRoute
          title="Comunicados"
          path="/communication-board/:id"
          component={EditCommunicationBoardPage}
        />
        <PrivateRoute
          title="E-mails Diários"
          path="/daily-emails"
          component={DailyEmails}
        />
        <PrivateRoute
          exact
          path="/news-single-page/:id"
          component={NewSinglePage}
        />
        <PrivateRoute exact path="/blip" title="Blip" component={Blip} />
        <PrivateRoute exact path="/ipc" title="IPC" component={Ipc} />
        <PrivateRoute
          exact
          path="/pop"
          title="Procedimento Operacional Padrão"
          component={PopPage}
        />
        <PrivateRoute
          exact
          title="Procedimento Operacional Padrão"
          path="/pop/:id"
          component={NewPopPage}
        />
        <PrivateRoute
          exact
          title="Recibos"
          path="/receipts"
          component={ReceiptsPage}
        />
        <PrivateRoute
          exact
          title="Ramais"
          path="/ramal"
          component={UsersRamalPage}
        />
        <PrivateRoute
          exact
          title="Agenda"
          path="/calendar"
          component={CalendarPage}
        />
        <PrivateRoute
          exact
          title="Dashboard - Marketing"
          path="/marketing-dashboard"
          component={MarketingDashboardPage}
        />
        <PrivateRoute
          exact
          title="Ilhas"
          path="/islands"
          component={IslandsPage}
        />
        <PrivateRoute
          exact
          title="Atendimento"
          path="/pipeline-calls"
          component={PipelineCalls}
        />
      </Switch>
    </Router>
  )
}

AppRouter.propTypes = {}

export default AppRouter
