import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Board from 'react-trello'

import { Creators as CallActions } from '~/store/ducks/call'

import CallDialog from '~/pages/CallsPage/components/CallDialog'
import history from '~/helpers/history'

const sessionSelector = ({ session }) => session
const callsSelector = ({ calls }) => calls

function BoardCalls({ dataCards }) {
  const { user } = useSelector(sessionSelector)
  const { dataPipeline } = useSelector(callsSelector)

  const dispatch = useDispatch()
  const [state, setState] = useState({ dataCards })
  const [openCallDialog, setOpenCallDialog] = useState(false)
  const [callId, setCallId] = useState(null)

  useEffect(() => {
    setState({ dataCards })
  }, [dataPipeline])

  const handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {
    const actions = {
      'Em atendimento': 4,
      'Aguardando atendimento': 1,
      Encerrado: 2,
      Reaberto: 3,
      Parado: 10,
    }

    const formattedData = {
      call_id: Number(cardId),
      action_id: actions[targetLaneId],
      user_id: user?.id,
    }

    if (sourceLaneId !== targetLaneId) {
      dispatch(
        CallActions.addCallActionsRequest(formattedData),
      )
    }
  }

  const setEventBus = (eventBus) => {
    setState((prevState) => {
      return { ...prevState, eventBus }
    })
  }

  const openDialogCall = (cardId) => {
    setOpenCallDialog(true)
    Number(cardId)
    setCallId(cardId)
  }

  const handleCloseCallDialog = () => {
    setOpenCallDialog(false)
    setCallId(null)
    history.push('/pipeline-calls')
  }

  return (
    <div
      style={{
        height: '100%',
        borderRadius: 6,
      }}
    >
      {dataCards && (
        <Board
          data={state?.dataCards || { lanes: [] }}
          laneDraggable
          hideCardDeleteIcon
          eventBusHandle={setEventBus}
          handleDragEnd={handleDragEnd}
          onCardClick={openDialogCall}
          style={{
            backgroundColor: '#E5E5E5',
            borderRadius: 4,
            height: '100%',
          }}
        />
      )}
      <CallDialog
        id={callId}
        open={openCallDialog}
        onClose={handleCloseCallDialog}
      />
    </div>
  )
}

BoardCalls.propTypes = {
  dataCards: PropTypes.shape({}).isRequired,
}

export default BoardCalls
