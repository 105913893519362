import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'
import {
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as EquipmentsActions } from '~/store/ducks/equipments'
import { Creators as EquipmentActions } from '~/store/ducks/equipment'

import Portlet from '~/components/Portlet'
import ListFooter from '~/components/ListFooter'
import OverlayLoading from '~/components/OverlayLoading'
import ConfirmDialog from '~/components/ConfirmDialog'
import EmptyList from '~/components/EmptyList'
import SearchInput from '~/components/SearchInput'

import EquipmentsList from '~/components/EquipmentsList'

import { useSmallScreen } from '~/helpers/useMediaQuery'

import EquipmentsDialog from './components/EquipmentsDialog'
import EquipmentsFilters from './components/EquipmentsFilters'

import styles from './styles'


const equipmentsSelector = ({ equipments }) => equipments

const useStyles = makeStyles(styles)

function EquipmentsPage() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSmallScreen = useSmallScreen()
  const { data, options, loading, exportedData  } = useSelector(equipmentsSelector)

  const { sort, direction } = options
  const [equipmentId, setEquipmentId] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  useEffect(() => {
    dispatch(EquipmentsActions.getEquipmentsRequest())
    dispatch(EquipmentsActions.getEquipmentsFilterEquipmentsTypeRequest())
    dispatch(EquipmentsActions.getEquipmentsFilterDealershipsRequest())
    dispatch(EquipmentsActions.getEquipmentsFilterDepartmentsRequest())
    dispatch(EquipmentsActions.getEquipmentsFilterResponsiblesRequest())

    return () => {
      dispatch(EquipmentsActions.resetEquipmentsFilters())
    }
  }, [])

  useEffect(() => {
    if (exportedData) {
      const blob = new Blob([exportedData], { type: 'application/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'equipments.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [exportedData])

  const handleSortChange = (newSort) => {
    const isSameSort = newSort === sort
    let sortDirection = direction

    if (isSameSort) {
      if (direction === 'asc') {
        sortDirection = 'desc'
      } else {
        sortDirection = 'asc'
      }
    }

    dispatch(EquipmentsActions.setEquipmentsSort(newSort, sortDirection))
  }

  const handleChangeRowsPerPage = (ev) => {
    const {
      target: { value },
    } = ev
    dispatch(EquipmentsActions.setEquipmentsPage(0, value))
  }

  const handleChangePage = (_, page) => {
    dispatch(EquipmentsActions.setEquipmentsPage(page, options.limit))
  }

  const handleEditEquipments = (id) => {
    setEquipmentId(id)
    setOpenDialog(true)
  }

  const handleAddEquipments = () => {
    setOpenDialog(true)
  }

  const handleExportEquipments = () => {
    dispatch(EquipmentsActions.exportEquipmentsRequest())
  }

  const handleClose = () => {
    setOpenDialog(false)
    setEquipmentId(null)
  }

  const handleRemoveEquipment = (id) => {
    setEquipmentId(id)
    setOpenConfirmDialog(true)
  }

  const handleRemoveCancel = () => {
    setEquipmentId(null)
    setOpenConfirmDialog(false)
  }

  const handleRemoveAccept = () => {
    dispatch(
      EquipmentActions.removeEquipmentRequest(equipmentId, () => {
        dispatch(EquipmentsActions.getEquipmentsRequest())
        setEquipmentId(null)
        setOpenConfirmDialog(false)
      }),
    )
  }

  const handleSearchChange = (value) => {
    dispatch(EquipmentsActions.setEquipmentsSearch(value))
  }

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.gridContainerButton}
          spacing={2}
        >
          {!isSmallScreen && (
            <Grid item xs={12} md={6}>
              <SearchInput
                onChange={(e) => handleSearchChange(e.target.value)}
                placeholder="Pesquise por código, marca e modelo..."
              />
            </Grid>
          )}
          <Grid item style={{ paddingTop: isSmallScreen ? 8 : '' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAdd}
              onClick={handleExportEquipments}
              style={{marginRight: '10px'}}
            >
              <GetAppIcon className={classes.iconAdd} /> Exportar CSV
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonAdd}
              onClick={handleAddEquipments}
            >
              <AddIcon className={classes.iconAdd} /> Adicionar equipamento
            </Button>
          </Grid>

        </Grid>
        {isSmallScreen && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes.filtersTitle}>
                <FilterIcon color="primary" className={classes.filterIcon} />
                <Typography variant="h5" color="primary">
                  Filtros
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SearchInput
                    onChange={(e) => handleSearchChange(e.target.value)}
                    placeholder="Pesquise código, marca e modelo..."
                  />
                </Grid>
                <Grid item xs={12}>
                  <EquipmentsFilters classes={classes} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {!isSmallScreen && <EquipmentsFilters classes={classes} />}
        <Portlet className={classes.grow}>
          <div className={classes.tableWrapper}>
            {!loading && data.length === 0 && (
              <EmptyList message="Não há equipamentos para serem visualizados" />
            )}
            {loading && <OverlayLoading />}
            {!loading && (
              <div className={classes.listWrapper}>
                {data.length > 0 && (
                  <EquipmentsList
                    handleEditEquipments={handleEditEquipments}
                    handleRemoveEquipment={handleRemoveEquipment}
                    handleSortChange={handleSortChange}
                  />
                )}
              </div>
            )}
          </div>

          {!loading && data.length > 0 && (
            <ListFooter
              options={options}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
            />
          )}
        </Portlet>
      </div>
      <EquipmentsDialog
        open={openDialog}
        onClose={handleClose}
        id={equipmentId}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        title="Atenção"
        description="Deseja realmente excluir equipamento?"
        onCancel={handleRemoveCancel}
        onAccept={handleRemoveAccept}
      />
    </>
  )
}

export default EquipmentsPage
