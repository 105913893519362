import { Avatar, Grid, Table, TableCell, TableFooter, TableRow, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as CallsActions } from '~/store/ducks/calls'
import BoardCalls from './components/BoardCalls'

const callsSelector = ({ calls }) => calls

const Pipeline = () => {
  const dispatch = useDispatch()
  const { dataPipeline, countdownPipeline: { count }, } = useSelector(callsSelector)

  useEffect(() => {
    dispatch(CallsActions.getCallsPipelineRequest(() => {
      dispatch(CallsActions.startCallsPipelineCountdown())
    }))

    return () => {
      dispatch(CallsActions.resetCalls())
      dispatch(CallsActions.endCallsPipelineCountdown())
    }
  }, [])

  const createLanes = (status) => {
    const calls = dataPipeline?.filter((item) => item.status === status)

    const cards = calls
      ?.sort((a, b) => a.id - b.id)
      .map((item) => {
        const description = item.description.length > 150 ? `${item.description.substr(0, 150)}...` : item.description
        return {
          id: String(item.id),
          title: (
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              {`${item.subject}`}
            </Typography>
          ),
          description: (
            <Typography variant="h7"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          ),
          call: item,
          label: (
            <Grid
              container
              justifyContent="flex-end"
              style={{
                position: 'absolute',
                top: 3,
                bottom: 0,
                left: -15,
                right: 0,
                margin: 'auto',
              }}
            >
              <Grid item>
                <Avatar
                  src={`https://storage.googleapis.com/nissul-files-staging/${item.image}`}
                  style={{
                    backgroundColor: '#1B1464',
                    height: 30,
                    width: 30,
                  }}
                />
              </Grid>
            </Grid>
          ),
          cardStyle: {
            width: 270,
            maxWidth: 270,
            margin: 'auto',
            marginBottom: 5,
          },
        }
      })

    return {
      id: status,
      title: status,
      style: {
        color: '#3A3B3F',
        backgroundColor: 'rgba(78, 60, 147, 0.05)',
        maxHeight: '85vh',
      },
      cards,
    }
  }

  const waitingForServiceCards = createLanes(
    'Aguardando atendimento',
    '#FF7B00',
  )
  const inServiceCards = createLanes('Em atendimento', '#65B163')
  const closedCards = createLanes('Encerrado', '#1665D8')
  const stopedCards = createLanes('Parado', '#E53935')
  const reopenedCards = createLanes('Reaberto', '#C68400')

  const data = {
    lanes: [
      waitingForServiceCards,
      inServiceCards,
      reopenedCards,
      stopedCards,
      closedCards,
    ],
  }

  useEffect(() => {
    if (count === 0 || count === 15 || count === 30 || count === 45) {
      dispatch(CallsActions.getCallsPipelineRequest())
    }
  }, [count])

  return (
    <div style={{ padding: '16px 16px 0 16px', height: 'calc(100% - 57px)' }}>
      {dataPipeline.length > 0 && <BoardCalls dataCards={data} />}
      <div>
        <Table size="small">
          <TableFooter>
            <TableRow>
              <TableCell>Atualizando em {count} segundos</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  )
}

export default Pipeline
