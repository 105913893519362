import React from 'react'
import PropTypes from 'prop-types'
import { sortBy, find } from 'lodash'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '~/components/Autocomplete'

import { Creators as EquipmentsActions } from '~/store/ducks/equipments'

const equipmentsTypesFilterSelector = ({ equipments }) =>
  equipments.equipmentsTypes
const departmentsSelector = ({ equipments }) => equipments.departments
const responsiblesSelector = ({ equipments }) => equipments.responsibles

const dealershipsFilterSelector = ({ equipments }) => equipments.dealerships
const equipmentsSelector = ({ equipments }) => equipments

export default function EquipmentsFilters({ classes }) {
  const dispatch = useDispatch()

  const activeResponsibleFilter = [
    { label: 'Sim', value: true },
    { label: 'Não', value: 'false' },
  ]
  const conservationsOptions = [
    { value: 1, label: 'Bom' },
    { value: 2, label: 'Regular' },
    { value: 3, label: 'Ruim' },
  ]

  const {
    data: equipmentsTypesFilter,
    loading: loadingEquipmentsTypesFilter,
  } = useSelector(equipmentsTypesFilterSelector)
  const {
    data: dealershipsFilter,
    loading: loadingdealershipsFilter,
  } = useSelector(dealershipsFilterSelector)
  const {
    data: departmentsFilter,
    loading: loadingdepartmentsFilter,
  } = useSelector(departmentsSelector)
  const {
    data: responsiblesFilter,
    loading: loadingresponsiblesFilter,
  } = useSelector(responsiblesSelector)
  const { options } = useSelector(equipmentsSelector)

  const getFilterValue = (property) => {
    if (options.filters) {
      const filterValue = find(options.filters, { name: property })
      return filterValue.value
    }
    return null
  }

  const handlePropertyFilterChange = (property, value) => {
    dispatch(EquipmentsActions.setEquipmentsPropertyFilter(property, value))
  }
  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        spacing={2}
        className={classes.filtersWrapper}
      >
        <Grid item xs={12} md={2} lg={2}>
          <Autocomplete
            onChange={(_, option) => {
              handlePropertyFilterChange(
                'equipment_type_id',
                option ? option.value : null,
              )
            }}
            options={equipmentsTypesFilter.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            name="equipment_type_id"
            value={getFilterValue('equipment_type_id')}
            className={classes.autocomplete}
            label="Filtrar por Tipo"
            placeholder="Selecione um Tipo"
            loading={loadingEquipmentsTypesFilter}
            autoSelect={false}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Autocomplete
            onChange={(_, option) => {
              handlePropertyFilterChange(
                'dealership_id',
                option ? option.value : null,
              )
            }}
            options={sortBy(dealershipsFilter, (a) => a.name).map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            name="dealership_id"
            value={getFilterValue('dealership_id')}
            className={classes.autocomplete}
            label="Filtrar por Concessionária"
            placeholder="Selecione um Concessionária"
            loading={loadingdealershipsFilter}
            autoSelect={false}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Autocomplete
            onChange={(_, option) => {
              handlePropertyFilterChange(
                'department_id',
                option ? option.value : null,
              )
            }}
            options={departmentsFilter.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            name="department_id"
            value={getFilterValue('department_id')}
            className={classes.autocomplete}
            label="Filtrar por Departamento"
            placeholder="Selecione um Departamento"
            loading={loadingdepartmentsFilter}
            autoSelect={false}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={4}>
          <Autocomplete
            onChange={(_, option) => {
              handlePropertyFilterChange(
                'responsible_id',
                option ? option.value : null,
              )
            }}
            options={responsiblesFilter.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            name="responsible_id"
            value={getFilterValue('responsible_id')}
            className={classes.autocomplete}
            label="Filtrar por Responsável"
            placeholder="Selecione um Responsável"
            loading={loadingresponsiblesFilter}
            autoSelect={false}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Autocomplete
            onChange={(_, option) => {
              handlePropertyFilterChange('responsible_active', option ? option.value : null)
            }}
            options={activeResponsibleFilter}
            name="responsible_active"
            getOptionSelected={(option, val) => {
              return option.value === val
            }}
            value={getFilterValue('responsible_active')}
            className={classes.autocomplete}
            label="Responsável Ativo"
            placeholder="Ativo"
            autoSelect={false}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Autocomplete
            onChange={(_, option) => {
              handlePropertyFilterChange('conservation_state', option ? option.value : null)
            }}
            options={conservationsOptions}
            name="conservation_state"
            getOptionSelected={(option, val) => {
              return option.value === val
            }}
            value={getFilterValue('conservation_state')}
            className={classes.autocomplete}
            label="Estado de Conservação"
            placeholder="Filtrar por estado de conservação"
            autoSelect={false}
          />
        </Grid>
      </Grid>
    </>
  )
}

EquipmentsFilters.propTypes = {
  classes: PropTypes.shape({
    autocomplete: PropTypes.string,
    filtersWrapper: PropTypes.string,
  }).isRequired,
}
