import { call, takeLatest, put, select, debounce } from 'redux-saga/effects'
import { find } from 'lodash'
import moment from 'moment'
import configureApi from '~/helpers/api'

import { Types as UsersTypes, Creators as UsersActions } from '../ducks/users'

const optionsSelector = ({ users }) => users.options

function* getUsers() {
  const options = yield select(optionsSelector)
  const getFilter = (property) => {
    const filterValue = find(options.filters, { name: property })
    return filterValue.value
  }

  try {
    const api = configureApi()
    const month = String(moment(getFilter('month')).month() + 1)
    const formattedMonth = month.padStart(2, '0')
    const monthNumber = Number(formattedMonth)
    const search = []
    if (options.search) {
      search.push(`ramal_next_ip,${options.search}`)
      search.push(`name,${options.search}`)
    }
    let formattedParams = {
      pagination: 1,
      page: options.page + 1,
      limit: options.limit,
      order: `${options.sort},${options.direction}`,
      search,
      'where[function_dealership_id]': getFilter('dealership_id'),
      'where[gender]': getFilter('gender'),
      'where[function_id]': getFilter('function_id'),
    }

    if (getFilter('active')) {
      formattedParams['where[active]'] = getFilter('active')
    }

    if (monthNumber >= 1 && monthNumber <= 12) {
      formattedParams = {
        ...formattedParams,
        month: formattedMonth,
      }
    }

    const { data } = yield call(api.get, 'users', {
      params: formattedParams,
    })
    yield put(UsersActions.getUsersSuccess(data.data, data.total))
  } catch (e) {
    yield put(UsersActions.getUsersFailure())
  }
}

function* reloadUsers({ ramal }) {
  yield put(UsersActions.getUsersRequest(ramal))
}

function* getUsersFilterDealerships() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'dealerships', {
      params: {
        pagination: 0,
        order: 'sort,asc',
      },
    })
    yield put(UsersActions.getUsersFilterDealershipsSuccess(data))
  } catch (e) {
    yield put(UsersActions.getUsersFilterDealershipsFailure())
  }
}

function* getUsersFilterFunctions() {
  try {
    const api = configureApi()
    const { data } = yield call(api.get, 'functions', {
      params: {
        pagination: 0,
        order: 'name,asc',
      },
    })
    yield put(UsersActions.getUsersFilterFunctionsSuccess(data))
  } catch (e) {
    yield put(UsersActions.getUsersFilterFunctionsFailure())
  }
}

export default function* () {
  yield takeLatest(UsersTypes.GET_USERS_REQUEST, getUsers)
  yield takeLatest(UsersTypes.SET_USERS_SORT, reloadUsers)
  yield takeLatest(UsersTypes.SET_USERS_PAGE, reloadUsers)
  yield debounce(1000, UsersTypes.SET_USERS_SEARCH, reloadUsers)
  yield takeLatest(UsersTypes.SET_USERS_PROPERTY_FILTER, reloadUsers)
  yield takeLatest(
    UsersTypes.GET_USERS_FILTER_DEALERSHIPS_REQUEST,
    getUsersFilterDealerships,
  )
  yield takeLatest(
    UsersTypes.GET_USERS_FILTER_FUNCTIONS_REQUEST,
    getUsersFilterFunctions,
  )
}
